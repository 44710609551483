// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view), mini laptops
$screen-md-min: 769px;

// Laptop
$screen-lp-min: 1024px;

$screen-lg-min: 1366px;

$screen-xl-min: 1920px;

/**
 * Breakpoint mixins
 **/

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Laptops
@mixin lp {
  @media (min-width: #{$screen-lp-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}

@mixin ios {
  @supports (-webkit-touch-callout: none) {
    @content;
  }
}
