@import '../../styles/breakpoint';
@import '../../styles/colors';
@import '../../styles/typography';

.Flyout {
  height: calc(100vh - 81px) !important;
  bottom: 0 !important;
  top: initial !important;

  p {
    color: $text-dark;
  }

  &_options {
    position: absolute !important;
    top: 32px;
    right: 32px;
    z-index: 2;

    & > button {
      display: inline-flex;
      width: 24px;
      height: 24px;
      padding: 0;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background-color: $soap-1;
      cursor: pointer;
      -webkit-appearance: none;
      outline: none;
      border: none;
    }

    & > ul {
      right: -2px;
      width: max-content;
      min-width: 140px;
      left: unset;
      transform: unset;

      li {
        font-weight: normal;
        padding: 10px 16px;
      }
    }
  }

  &_title {
    @include heading-5;
    margin-bottom: 6px;
  }

  &_description {
    @include body-1;
  }

  &_content {
    height: 100%;
    display: grid;
    max-width: 100%;
    grid-template-rows: minmax(144px, max-content) minmax(max-content, 1fr);

    & > button {
      z-index: 1;
      svg {
        width: 24px;
        height: 24px;
        circle {
          fill: none;
        }
        path {
          width: 7px;
          height: 7px;
          stroke: $licorice-4;
        }
      }
    }

    & > header {
      background-color: $soap-1;
      width: 100%;
      padding: 80px 32px 16px 32px;
    }

    & > footer {
      position: absolute;
      width: 100%;
      height: max-content;
      background-color: $white-1;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 24px 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        width: 194px;
        height: 48px;
      }
    }

    &_body {
      height: calc(100vh - 225px) !important;
      overflow: scroll;

      &_full {
        height: 100% !important;
      }
    }
  }
  @include lp {
    &_content {
      max-width: 475px;
    }
  }
}
