@import '../../../../styles/colors';
@import '../../../../styles/typography';

.SessionTimeout {
  height: 100vh;
  max-height: unset !important;
  border-radius: 0 !important;

  & > button {
    display: none;
  }

  &_backdrop {
    backdrop-filter: blur(27.1828px);
    background-color: $white-1 !important;
    z-index: 9000 !important;
  }

  &_card,
  &_form {
    z-index: 99;
    width: 100%;
    max-width: 540px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 48px 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &_ttl {
      @include heading-3();
    }
    &_desc {
      @include body-1();
      margin-top: 16px;
      text-align: center;
    }
    &_btn {
      margin-top: 48px;
      min-width: 160px;
    }
  }

  &_form {
    box-shadow: none;
  }
}
