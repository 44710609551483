@import '../../../../styles/colors';
@import '../../../../styles/typography';
@import '../../../../styles/breakpoint';

.FaqFlyout {
  width: 100% !important;
  height: 100vh !important;

  & > button,
  header {
    display: none;
  }

  &_mobile {
    display: flex;
    align-items: center;
    padding: 16px 30px;
    border-bottom: 1px solid $licorice-1;

    &_close {
      width: 24px;
      height: 24px;
      margin-right: 37px;
    }

    &_title {
      @include heading-4;
      color: $licorice-6;
    }
  }

  @include sm() {
    height: calc(100vh - 81px);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

    & > button,
    header {
      display: block;
    }

    &_mobile {
      display: none;
    }
  }

  &_content {
    background: $white-1;
    height: 100% !important;

    @include sm {
      height: calc(100vh - 278px) !important;
    }

    &_item {
      background-color: $white-1;
      border-bottom-color: $soap-2 !important;
      padding: 16px 24px;

      @include sm {
        padding: 32px 24px 32px 32px !important;
      }

      div {
        &:not(:first-of-type) {
          @include body-2;
          width: 90%;
        }

        h6 {
          @include body-1;
          font-size: 14px !important;
          font-weight: 500 !important;
          color: $text-dark !important;
        }
      }
    }
  }

  &_footer {
    display: block;

    &_card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 16px 26px;

      &_content {
        display: flex;
        align-items: center;

        &_text {
          margin-left: 8px;

          &_title {
            @include body-2;
            font-weight: 500;
          }

          &_desc {
            @include body-2;
          }
        }
      }

      &_link {
        color: $theme-color;
        @include body-2;
        font-weight: 500;
        width: unset !important;
      }
    }
  }

  @media screen and (max-width: 300px) {
    &_card {
      flex-direction: column;
      align-items: flex-start;

      &_link {
        margin-top: 10px;
      }
    }
  }
}
