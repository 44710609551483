@import './../../../../styles/breakpoint';

.header {
  width: 100%;
  height: 80px;
  display: none;
  align-items: center;
  padding-inline: 150px;
  justify-content: space-between;
  @include lp {
    display: flex;
  }
}
