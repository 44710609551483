@import '../../styles/typography';
@import '../../styles/colors';
@import '../../styles/breakpoint';

.ErrorBoundary {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 30px;

  &_for {
    &_route {
      height: 100% !important;
    }
  }

  & > svg {
    width: 65px;
    height: 65px;
  }

  & > h1 {
    margin: 16px 0 20px;
  }

  & > p {
    @include body-1;
    font-weight: 500;

    &:nth-of-type(2) {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }

    & > button {
      margin-left: 4px;
      text-decoration: underline;
    }
  }

  & > button {
    margin: 32px 0 60px;
    min-width: 190px;
  }
}
