@import './../../../../styles/breakpoint';
@import './../../../../styles/typography';
.login {
  display: flex;
  height: 100%;
  flex-direction: column;
  &_content {
    flex: 1;
    display: flex;
    padding: 46px 16px 59px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  &_form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &_formField {
    margin-bottom: 24px;
  }
  &_formBtn {
    margin-top: 9px;
  }
  &_formTitle {
    @include heading-1;
    color: $licorice-6;
    text-align: center;
    margin-bottom: 45px;
  }
  &_contentFooter,
  &_formFooter {
    @include small-text();
    display: flex;
    margin-top: auto;
    width: max-content;
    color: $licorice-4;
    margin-inline: auto;
    a {
      margin-left: 6px;
      color: $licorice-6;
      text-decoration: underline;
    }
  }
  &_formFooter {
    margin-top: 16px;
  }
  @include ios {
    height: -webkit-fill-available;
  }
  @include lp {
    &_form {
      max-width: 344px;
    }
    &_formTitle {
      text-align: left;
      margin-bottom: 36px;
    }
    &_contentFooter {
      display: none;
    }
  }
}
