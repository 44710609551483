@import './../../../../styles/colors';
@import './../../../../styles/typography';

.PasswordInput {
  &_wrapper {
    position: relative;
  }

  &_status {
    font-weight: 500;
    font-size: 12px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: $theme-color;
    outline: none;
    line-height: 1;
  }
}
